const Footer = () => {
    return (
        <footer className="container">
            <div className="footer-left">
                <p className="text-lg text-danger bold mb-2">GET IN TOUCH</p>
                <p className="text-md">I have a full time gig, but I enjoy doing freelance work on my spare time. Don't hesitate to reach out!</p>
            </div>
            <div className="footer-right">
                <div className="footer-socials mb-1">
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/vaibhavrawat-dev/" title="My LinkedIn" alt="My LinkedIn">
                        <i className="fa fa-linkedin-square fa-3x" aria-hidden="true"></i>
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/vaibhav_rwtt/" title="My Instagram" alt="My Instagram">
                        <i className="fa fa fa-instagram fa-3x" aria-hidden="true"></i>
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://github.com/Vaibhav-rawat" title="My GitHub" alt="My GitHub">
                        <i className="fa fa-github-square fa-3x" aria-hidden="true"></i>
                    </a>
                </div>
                <div className="footer-contact">
                    {/* <p className="text-md m-r-1">
                        <a href="tel:+91 8006902440" className="text-light text-decoration-none" title="My Mobile Number" alt="My Mobile Number">
                            <i className="fa fa-phone-square m-r-1" aria-hidden="true"></i>+91 8006902440
                        </a>
                    </p> */}
                    <p className="text-md">
                        <a href="mailto:rawat4vaibhav@gmail.com" className="text-light text-decoration-none" title="My Email Address" alt="My Email Address">
                            <i className="fa fa-envelope m-r-1" aria-hidden="true"></i>rawat4vaibhav@gmail.com
                        </a>
                    </p>
                </div>
                <p className="text-md">
                    <i className="fa fa-copyright m-r-1" aria-hidden="true"></i>2023 <span className="text-danger bold">Vaibhav Rawat.</span>
                </p>
            </div>

        </footer>
    )
}

export default Footer;