import jslogo from '../images/js-logo.svg';
import htmllogo from '../images/html-logo.svg';
import csslogo from '../images/css-logo.svg';
import reactlogo from '../images/react-logo.svg';
import nodejslogo from '../images/node-js-logo.svg';
import mongodblogo from '../images/mongo-db-logo.svg';

import { useEffect, useRef } from 'react';

const Intro = () => {

    const ref = useRef(null);
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let interval = null;


    useEffect(() => {
        if (ref.current) {
            const event = new MouseEvent('mouseover', {
                bubbles: true, // Ensure the event bubbles up the DOM tree
                cancelable: true, // Allow the event to be cancelled
            });
            ref.current.dispatchEvent(event);
        }
    }, [])

    
    const glitchEffect = (ele) => {
        let iteration = 0;
  
        clearInterval(interval);
  
        interval = setInterval(() => {
            ele.target.innerText = ele.target.innerText
                .split("")
                .map((letter, index) => {
                    if(index < iteration) {
                    return ele.target.dataset.value[index];
                }
            
                return letters[Math.floor(Math.random() * 26)]
            }).join("");
        
            if(iteration >= ele.target.dataset.value.length){ 
                clearInterval(interval);
            }
        
            iteration += 1 / 3;
        }, 50);

    } 




    return (
        <section id="home-section" className="container intro-section"> 
            <div className="relative intro-img-div">
                <img src={require("../images/prof-img-v1.png")} height="450" width="450" alt="Vaibhav Rawat" title="It's Me, Vaibhav Rawat!"/>
            </div>
            <div className="intro-summary">
                <h1 className="text-xl text-danger"><span ref={ref} data-value="Full-Stack" onMouseOver={ glitchEffect }  className="text-danger text-explode d-inline-block">Full-Stack</span> <br/>Web Developer</h1>
                <p className="text-md mt-1">Hi, I’m <span className="text-danger bold">Vaibhav Rawat</span>. A passionate MERN Stack Web Developer based in Delhi, India.</p>
                <div className="skills-strip">
                    <img title="Javascript" alt='js-dark-logo' className='animate-into-view animate animation-delay--fastest' src={jslogo}/>
                    <img title="HTML5" alt='html-dark-logo' className='animate-into-view animate animation-delay--faster' src={htmllogo}/>
                    <img title="CSS3" alt='css-dark-logo' className='animate-into-view animate animation-delay--fast' src={csslogo}/>
                    <img title="React" alt='react-dark-logo' className='animate-into-view animate animation-delay--slow' src={reactlogo}/>
                    <img title="Node.js" alt='node-js-dark-logo' className='animate-into-view animate animation-delay--slower' src={nodejslogo}/>
                    <img title="Mongo DB" alt='mongo-db-dark-logo' className='animate-into-view animate animation-delay--slowest' src={mongodblogo}/>
                </div>
            </div>
        </section>
    )
}

export default Intro;