import hamburgerOpenIcon from '../images/hamburger-open-icon.svg';
import hamburgerCloseIcon from '../images/hamburger-close-icon.svg';

import { useEffect, useRef } from 'react';

const Header = ({ mainRef }) => {

    const handleClick = (anchor) => {
        const id = `${anchor}-section`;
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
    };

    const headerRef = useRef();

    let prevScrollPos = window.scrollY;

    const handleScroll = () => {

        const currentScrollPos = window.scrollY;

        if( ( currentScrollPos < 0 || prevScrollPos < 0 ) || ( currentScrollPos === prevScrollPos )) return;

        if (currentScrollPos < prevScrollPos) {
            headerRef.current.style.top = '0';
        } else {
            headerRef.current.style.top = '-10%';
        }

        prevScrollPos = currentScrollPos;

    }


    useEffect(() => {

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        };
        
    });

    let firstLoad = true;

    const toggleMobMenu = ( anchor = null ) => {

        if(mainRef.current.classList.contains("mob-menu-visible")){
            mainRef.current.classList.remove("mob-menu-visible");
            document.body.style.overflow = '';
        } else {
            mainRef.current.classList.add("mob-menu-visible");
            document.body.style.overflow = 'hidden';
        }

        if(!firstLoad) mainRef.current.classList.toggle("mob-menu-not-visible");

        firstLoad = false;
        
        if(anchor) handleClick(anchor);

    }

    return (
        <>
        <header ref={headerRef} className="container">
            <div className="text-lg bold dev-name">
                vaibhav.dev<span className="text-danger">_</span>
            </div>
            <ul className="text-md bold">
                <li><a className="anchor-link" href="/#home" onClick={ ()=> handleClick('home') } >Home</a></li>
                <li><a className="anchor-link" href="/#about" onClick={ ()=> handleClick('about') } >About</a></li>
                <li><a className="anchor-link" href="/#skills" onClick={ ()=> handleClick('skills') } >Skills</a></li>
                <li><a className="anchor-link" href="/#projects" onClick={ ()=> handleClick('projects') } >Projects</a></li>
                <li><a className="anchor-link" href="/#experience" onClick={ ()=> handleClick('experience') } >Experience</a></li>
                <li><a className="anchor-link anchor-link-active" href="https://flowcv.com/resume/01oc5juss1" target="_blank" rel="noreferrer">Resume</a></li>
                <li onClick={ () => toggleMobMenu() } >
                    <img alt="Open Mobile Menu" className='hamburger-img' title="Open Mobile Menu" src={hamburgerOpenIcon} height="38" width="38"/>
                </li>
            </ul>
        </header>
        <div className='mobile-cover-menu menu-layer-1'></div>
        <div className='mobile-cover-menu menu-layer-2'></div>
        <div className='mobile-cover-menu menu-layer-3'>
            <ul className="text-danger text-lg mob-menu-links bold">
                <li>
                    <a className="anchor-link" href="/#home" onClick={() => toggleMobMenu('home') }>Home</a>
                </li>
                <li>
                    <a className="anchor-link" href="/#about" onClick={() => toggleMobMenu('about') }>About</a>
                </li>
                <li>
                    <a className="anchor-link" href="/#skills" onClick={() => toggleMobMenu('skills') }>Skills</a>
                </li>
                <li>
                    <a className="anchor-link" href="/#projects" onClick={() => toggleMobMenu('projects') }>Projects</a>
                </li>
                <li>
                    <a className="anchor-link" href="/#experience" onClick={() => toggleMobMenu('experience') }>Experience</a>
                </li>
                <li>
                    <a className="anchor-link anchor-link-active" href="https://flowcv.com/resume/01oc5juss1" target="_blank" rel="noreferrer">Resume</a>
                </li>

                <li onClick={() => toggleMobMenu() } >
                    <img className='hamburger-close-menu' src={hamburgerCloseIcon} alt="Close Mobile Menu" title="Close Mobile Menu" height="25" width="25"/>
                </li>
            </ul>
        </div>
        </>
    )
};

export default Header;