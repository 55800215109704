import externaLinkIcon from '../images/external-link-icon.svg';

import { useInView } from "react-intersection-observer";

const Experience = () => {

    const { ref : parentRef, inView : parentInView } = useInView(
        {
            "triggerOnce" : true,
            "rootMargin" : "-100px 0px",
            "fallbackInView": true
        }
    );

    const { ref , inView } = useInView(
        {
            "triggerOnce" : true,
            "rootMargin" : "-250px 0px",
            "fallbackInView": true
        }
    );

    return (
        <section id="experience-section" className="container my-2">
            <div ref={parentRef} className={`animate-into-view ${ parentInView ? 'animate' : ''}`}>
                <h3 className="text-lg text-center">EXPERIENCE</h3>
                <div ref={ref} className={`experience-timeline py-4 relative ${ inView ? 'show-experience-timeline' : ''}`}>
                <div className="organisation relative">
                        <h3 className="organisation-name text-lg text-danger relative">
                            <a target="_blank" href="https://www.fitbots.com/" rel="noreferrer">Fitbots OKRs<img src={externaLinkIcon} alt="External Link" title="External Link" className='external-img' height="18" width="18"/></a>
                            <span className='text-sm'>(09/2023 - present)</span>
                        </h3>
                        <div className="organisation-info text-md">
                            <p className="organisation-post bold py-1">
                                Full Stack Engineer 
                            </p>
                            <ul className="organisation-work pt-1">
                                <li>Worked on Generative AI and AI prompt engineering to boost product adoption rate.</li>
                                <li>Enabled streaming of API responses to support streaming in the form of a custom scalable hook.</li>
                                <li>Implemented a solution to render Markdown API responses in a legible format, utilizing the Markdown library to ensure compliance with React security measures.</li>
                                <li>Led the seamless migration of the backend system from an older Node.js version (v10) to the latest one (v18), ensuring better performance and long-term sustainability.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="organisation relative">
                        <h3 className="organisation-name text-lg text-danger relative">
                            <a target="_blank" href="https://specscart.co.uk/" rel="noreferrer">Specscart<img src={externaLinkIcon} alt="External Link" title="External Link" className='external-img' height="18" width="18"/></a>
                            <span className='text-sm'>(05/2021 - 09/2023)</span>
                        </h3>
                        <div className="organisation-info text-md">
                            <p className="organisation-post bold py-1">
                                Full Stack Developer 
                            </p>
                            <ul className="organisation-work pt-1">
                                <li>Executed solo and team projects, developing full-stack applications with HTML5, CSS3, JavaScript, React, Node.js, and MongoDB.</li>
                                <li>Built and designed several libraries, to reduce dependence on third-party solutions, resulting in more efficient page load times.</li>
                                <li>Designed an internal system to track each stage of a product for efficient time management within the warehouse.</li>
                                <li>Successfully integrated PayPal, Stripe, and Square payment gateways, expanding payment options for customers.</li>
                                <li>Integrated logistics & shipping software such as ShipStation for a better hassle-free delivery system.</li>
                                <li>Automated Excel-based tasks, shifting them to our system, and utilized chart libraries for clear data visualization.</li>
                                <li>Curated detailed walk-through videos and documentation for any new functionality or feature.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Experience;