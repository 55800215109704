import quotelogo from '../images/quote-logo.svg';
import downarrow from '../images/down-arrow.svg';

const Quote = () => {
    return (
        <section className="container quote-wrapper relative mb-3">
            <img title="Quote" alt="Quote" src={quotelogo}/>
            <p className="text-gray text-md animate-into-view animate text-center">
                For Me, Web Development is not just a career path or a source of income, Web Development is something which excites me, every day there is a new learning, a new possibility to explore, it's a topic for me to discuss with someone for hours. For Me, Web Development is Passion!
            </p>
            <p className="bold text-end text-md mt-2">
                <i>~A Nerd Developer</i> &#129299;
            </p>
            <img title="Scroll Down" alt="Scroll Down" className="text-center down-arrow" src={downarrow}/>
        </section>
    )
}

export default Quote;