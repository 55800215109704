import './App.css';
import Header from './components/Header';
import Intro from './components/Intro';
import Quote from './components/Quote';
import Welcome from './components/Welcome';
import Footer from './components/Footer';
import Skills from './components/Skills';
import Experience from './components/Experience';
import Education from './components/Education';

import { useRef } from 'react';
import Projects from './components/Projects';

function App() {

  const ref = useRef(null);

  return (
    <main ref={ref}>
      <Header mainRef={ref}/>
      <Intro />
      <Quote />
      <Welcome />
      <Skills />
      <Projects />
      <Experience />
      <Education />
      <Footer />
    </main>
  );
}

export default App;
