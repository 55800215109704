
import { useInView } from "react-intersection-observer";

const Education = () => {

    const { ref : parentRef, inView : parentInView } = useInView(
        {
            "triggerOnce" : true,
            "rootMargin" : "-100px 0px",
            "fallbackInView": true
        }
    );

    return (
        <section id="education-section" className="container">
            <div ref={parentRef} className={`animate-into-view ${ parentInView ? 'animate' : ''}`}>
                <h3 className="text-lg text-center mb-1">Education</h3>


                <div className="education-timeline-wrapper">
                    <div className="education-timeline text-md mb-2">
                        <div className="education__card">
                            <h4 className="mb-2">Master of Computer Application</h4>
                            <p className="text-danger"> &#8627; Graphic Era Deemed To Be University, Dehradun </p>
                            <p className="text-xs"> (2019 - 2021) </p>
                        </div>
                        <div className="education__card">
                            <h4 className="mb-2">Bachelor in Computer Application</h4>
                            <p className="text-danger"> &#8627; Graphic Era Deemed To Be University, Dehradun </p>
                            <p className="text-xs"> (2016 - 2019) </p>
                        </div>
                        <div className="education__card">
                            <h4 className="mb-2">Higher Secondary Education</h4>
                            <p className="text-danger"> &#8627; R.P Public School, Kotdwara</p>
                            <p className="text-xs"> (2015 - 2016) </p>
                        </div>
                        <div className="education__card">
                            <h4 className="mb-2">Secondary School Education</h4>
                            <p className="text-danger"> &#8627; Krist Jayanti School, Najibabad </p>
                            <p className="text-xs"> (2013 - 2014)</p>
                        </div>
                    </div>
                    <div className="education-timeline-stream"></div>
                </div>
            </div>

        </section>
    )
}

export default Education;