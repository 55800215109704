
import { useInView } from 'react-intersection-observer';

const Welcome = () => {

    const { ref : parentRef, inView : parentInView } = useInView(
        {
            "triggerOnce" : true,
            "rootMargin" : "-100px 0px",
            "fallbackInView": true
        }
    );

    return (
        <section id="about-section" className="container mb-5">
            <div ref={parentRef} className={`animate-into-view ${ parentInView ? 'animate' : ''}`}>
                <h2 className="text-lg mb-3 text-center"> WELCOME TO MY PORTFOLIO </h2>
                <p className="text-md text-center text-gray mb-1">With <span className='bold'>3 years of hands-on experience</span>, I am a dedicated <span className='bold'>Web developer</span> adept at seamlessly integrating technologies to craft sophisticated solutions. I closely observe the web and construct scalable components, contributing to faster product adoption.</p>
                <p className="text-md text-center text-gray">This portfolio is my attempt to use no <span className='bold'>CSS</span> framework or any <span className='bold'>Javascript</span> animation library to achieve all the flashy and fun stuff that we see all over the internet, for which quite a lot developers prefer libraries, e.g., AOS, Scroll Magic, Anime.js, Wow.js, etc.</p>
            </div>

        </section>
    )
}

export default Welcome;