import reactJsColorLogo from '../images/react-js-color-logo.svg';
import bootstrapColorLogo from '../images/bootstrap-color-logo.svg';
import css3ColorLogo from '../images/css-3-color-logo.svg';
import html5ColorLogo from '../images/html-5-color-logo.svg';
import jsColorLogo from '../images/js-color-logo.svg';
import mongoDbColorLogo from '../images/mongo-db-color-logo.svg';
import nodeJsColorLogo from '../images/node-js-color-logo.svg';
import jQueryLogo from '../images/jQuery-logo.svg';
import firebaseLogo from '../images/firebase-logo.svg';

import { useInView } from 'react-intersection-observer';
import SkillCard from './SkillCard';

const Skills = () => {

    const { ref : parentRef, inView : parentInView } = useInView(
        {
            "triggerOnce" : true,
            "rootMargin" : "-100px 0px",
            "fallbackInView": true
        }
    );

    return (
        <section id="skills-section" className="container my-6">
            <div ref={parentRef} className={`animate-into-view ${ parentInView ? 'animate' : ''}`}>

                <h3 className="text-lg text-center mb-1">LANGUAGES I SPEAK</h3>
                <div className="skills-card py-2">
                    <SkillCard src={html5ColorLogo} title="HTML5" height={50} width={50} animateAfter={0}/>
                    <SkillCard src={css3ColorLogo} title="CSS3" height={50} width={50} animateAfter={200}/>
                    <SkillCard src={reactJsColorLogo} title="React" height={50} width={50} animateAfter={400}/>
                    <SkillCard src={jsColorLogo} title="Javascript" height={60} width={60} animateAfter={600}/>
                    <SkillCard src={bootstrapColorLogo} title="Bootstrap" height={55} width={55} animateAfter={800}/>
                    <SkillCard src={mongoDbColorLogo} title="Mongo DB" height={75} width={75} animateAfter={1000}/>
                    <SkillCard src={nodeJsColorLogo} title="Node JS" height={70} width={70} animateAfter={1200}/>
                    <SkillCard src={jQueryLogo} title="jQuery" height={65} width={65} animateAfter={1400}/>
                    <SkillCard src={firebaseLogo} title="Firebase" height={55} width={55} animateAfter={1600}/>
                </div>
                <div className="text-md text-center mt-1">I also have hands-on experience with: 
                    <p className='mt-1'>
                        <span className='bold text-gray'> MySQL</span>, 
                        <span className='bold text-gray'> Styled Components</span>, 
                        <span className='bold text-gray'> GIT</span>, 
                        <span className='bold text-gray'> RESTful APIs</span>, 
                        <span className='bold text-gray'> JWT</span>, 
                        <span className='bold text-gray'> onScan.js</span>, 
                        <span className='bold text-gray'> ApexCharts.js</span>, 
                        <span className='bold text-gray'> Chart.js</span>, 
                        <span className='bold text-gray'> Datatables</span>, 
                        <span className='bold text-gray'> Select2</span>, 
                        <span className='bold text-gray'> SweetAlert2</span>, 
                        <span className='bold text-gray'> Formik</span>, 
                        <span className='bold text-gray'> Chakra UI</span>,
                        <span className='bold text-gray'> Owl Carousel</span>,
                        <span className='bold text-gray'> Yep</span> & 
                        <span className='bold text-gray'> Flickity</span>
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Skills;