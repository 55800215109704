import homePageVideo from '../video/home-page-video.mp4';
import luxuryPageVideo from '../video/luxury-page-video.mp4';
import homePagePoster from '../images/home-page-poster.png';
import luxuryPagePoster from '../images/luxury-page-poster.png';

import { useInView } from 'react-intersection-observer';

const Projects = props => {

    const { ref : parentRef, inView : parentInView } = useInView(
        {
            "triggerOnce" : true,
            "rootMargin" : "-100px 0px",
            "fallbackInView": true
        }
    );

    return (
        <section id="projects-section" className="container my-6">
            <div ref={parentRef} className={`animate-into-view ${ parentInView ? 'animate' : ''}`}>

                <h3 className="text-lg text-center mb-3">PROJECTS</h3>
                <div className='projects-wrapper text-center'>
                    <div className='project-container'>
                        <p className='text-md text-center mb-2'>
                            <span className='bold'>1.</span> Developed the landing page for the <a target="_blank" href="https://specscart.co.uk/" rel="noreferrer">organization</a> from scratch to improve SEO ranking and reduce page load time resulting in a reduction of bounce rate.
                        </p>
                        <a target="_blank" href="https://specscart.co.uk/" rel="noreferrer" className='video-anchor'>
                            <video className="object-fit-cover card-with-border card-with-border-hover project-video" poster={homePagePoster} autoPlay playsInline loop muted> 
                            <source src={homePageVideo} type="video/mp4"/>
                        </video>
                        </a>
                        <a target="_blank" href="https://specscart.co.uk/" rel="noreferrer" className='btn-crimson mt-2 text-md'>View</a>
                    </div>
                    <div className='project-container'>
                        <p className='text-md text-center mb-2'>
                            <span className='bold'>2.</span> Took on the challenge of developing a webpage for the organization that exudes a sense of luxury, contributing to the increase in sales of high-end products.
                        </p>
                        <a target="_blank" href="https://specscart.co.uk/glasses/luxury-glasses" rel="noreferrer" className='video-anchor'>
                            <video className="object-fit-cover card-with-border card-with-border-hover project-video" poster={luxuryPagePoster} autoPlay playsInline loop muted> 
                                <source src={luxuryPageVideo} type="video/mp4"/>
                            </video>
                        </a>
                        <a target="_blank" href="https://specscart.co.uk/glasses/luxury-glasses" rel="noreferrer" className='btn-crimson mt-2 text-md'>View</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Projects;