
import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from 'react';

const SkillCard = props => {

    const [ disableHover, setDisableHover ] = useState(true)

    const { ref : cardRef, inView : cardInView } = useInView(
        {
            "triggerOnce" : true,
            "rootMargin" : "-200px 0px",
            "fallbackInView": true
        }
    );

    useEffect(() => {
        setTimeout(() => {
            setDisableHover(cardInView);
        }, props.animateAfter);
    }, [cardInView, props.animateAfter] );

    return (
        <div className='text-center'>
            <div ref={cardRef} className={`skill-card card-with-border ${ disableHover ? "" : "card-with-border-hover" } margin-inline-auto`} data-title={props.title}>
                <img src={props.src} height={props.height} width={props.width} alt={props.title} title={props.title}/>
            </div>
            <p className='text-xs mt-1 bold text-gray'>{props.title}</p>
        </div>
    )
}

export default SkillCard;